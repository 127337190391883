import React, { useState, useEffect } from 'react';
import Nabvar from '../Components/Navbar/Navbar'
import Demo from '../Pages/Demo/Demo'
import baseURL from '../Components/url';

export default function IndexLayout() {

    const [config, setConfig] = useState([]);

    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = () => {
        fetch(`${baseURL}/processConfig.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setConfig(data.config[0] || []);
            })
            .catch(error => console.error('Error al cargar configuracion:', error));
    };

    return (
        <div className='section-bg-color'>
            <Nabvar config={config} />
            <div className='espaciobg'>

            </div>
            <Demo />
        </div>
    );
}
