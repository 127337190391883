import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import baseURL from '../url';
import './Checkout.css';
import 'swiper/swiper-bundle.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
export default function Success() {
    const query = useQuery();
    const krAnswer = query.get('kr-answer');
    const answerObject = JSON.parse(krAnswer);
    const {customer, orderDetails} = answerObject;
    const [cartItems, setCartItems] = useState([]);
    const clearCart = () => {
        setCartItems([]);
        localStorage.removeItem('cart');
    };
    useEffect(() => {
        clearCart();

    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className='CheckoutContain'>
            <ToastContainer />
            <div className=''>
            </div>
            <div className='checkoutForm d-block'>
                <div className='w-100'>
                    Gracias por tu preferencia. Tu reserva ha sido confirmada.
                </div>
                <div className='w-100 mt-4 overflow-auto'>
                    <table className='successTable'>
                        <tr>
                            <td><b>Destino</b></td>
                            <td><b>Cantidad</b></td>
                            <td><b>Precio</b></td>
                        </tr>
                        {customer.shoppingCart.cartItemInfo.map((item, index) => (
                            <><tr>
                                <td>{item.productLabel}</td>
                                <td className='text-center'>{item.productQty}</td>
                                <td className='text-center'>S/{item.productAmount}</td>
                            </tr></>
                        ))}
                    </table>
                    <h2 className='mt-4'>Detalle de la reserva</h2>
                    <table className='successTable'>
                        <tr>
                            <td>Id de la Orden</td>
                            <td>{orderDetails.orderId}</td>
                        </tr>
                        <tr>
                            <td>Precio</td>
                            <td>S/ {(orderDetails.orderTotalAmount / 100).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>Nombre</td>
                            <td>{customer.billingDetails.firstName}</td>
                        </tr>
                        <tr>
                            <td>Apellido</td>
                            <td>{customer.billingDetails.lastName}</td>
                        </tr>
                        <tr>
                            <td>Teléfono</td>
                            <td>{customer.billingDetails.phoneNumber}</td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>{customer.email}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
}
