import React, { useEffect, useState } from 'react';
import baseURL from '../url';
import './Checkout.css';
import 'swiper/swiper-bundle.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link as Anchor } from "react-router-dom";
import Select from "react-select";
export default function Checkout() {
    const [formToken, setFormToken] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastName] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [boardingPoint, setBoardingPoint] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [productos, setProductos] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [paymentConfig, setPaymentConfig] = useState({});

    const loadScripts = async (paymentConfig) => {
        console.log(paymentConfig);
        // Create and append the first script
        const script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.src = 'https://static.micuentaweb.pe/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js';
        script1.setAttribute('kr-public-key', paymentConfig.public_key);
        script1.setAttribute('kr-get-url-success', `${baseURL}success`);
        script1.setAttribute('kr-language', 'es-ES');
        script1.async = true;
        document.head.appendChild(script1);

        // Create and append the link
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://static.micuentaweb.pe/static/js/krypton-client/V4.0/ext/classic-reset.min.css';
        document.head.appendChild(link);

        // Create and append the second script
        const script2 = document.createElement('script');
        script2.src = 'https://static.micuentaweb.pe/static/js/krypton-client/V4.0/ext/classic.js';
        script2.async = true;
        document.head.appendChild(script2);
    };

    useEffect(() => {
        console.log('token', formToken);
    }, [formToken]);

    useEffect(() => {
        cargarProductos();
        getPaymentConfig();
    }, []);

    useEffect(() => {
        fetch(
          "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
        )
          .then((response) => response.json())
          .then((data) => {
            setCountries(data.countries);
            setSelectedCountry(data.userSelectValue);
          });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const createPayment = async (event) => {
        setButtonDisabled(true);
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('lastname', lastname);
            formData.append('documentType', documentType);
            formData.append('documentNumber', documentNumber);
            formData.append('phone', phone);
            formData.append('email', email);
            formData.append('country', selectedCountry.value);
            formData.append('boardingPoint', boardingPoint);
            cartItems.forEach((item, index) => {
                formData.append(`cartItems[${index}]`, JSON.stringify({id: item.idProducto, name: item.titulo, qty: item.cantidad, selectedDate: item.selectedDates}));
            });
            const response = await fetch(`${baseURL}/processPaymentIziPay.php`, {
                method: 'POST',
                body: formData,
            });
            
            const data = await response.json();
            setFormToken(data.token);
            loadScripts(paymentConfig);
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };

    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos || []);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };
    
    const getPaymentConfig = () => {
        fetch(`${baseURL}/paymentConfig.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setPaymentConfig(data.config[0] || []);
            })
            .catch(error => console.error('Error al cargar configuracion:', error));
    };

    useEffect(() => {
        const fetchCartItems = async () => {
            const cart = JSON.parse(localStorage.getItem('cart')) || [];
            console.log(cart);
            const promises = cart.map(async (cartItem) => {
                const producto = productos.find(producto => producto.idProducto === cartItem.idProducto);
                return {
                    ...producto,
                    cantidad: cartItem.cantidad,
                    selectedDates: cartItem.selectedDates,
                    item: cartItem.item,
                };
            });

            Promise.all(promises)
                .then((items) => {
                    setCartItems(items);
                })
                .catch((error) => {
                    console.error('Error al obtener detalles del carrito:', error);
                });
        };

        fetchCartItems();
    }, [productos]);

    useEffect(() => {
        const calculateTotalPrice = () => {
            const total = cartItems.reduce((acc, item) => acc + (item.precio * item.cantidad), 0);
            setTotalPrice(total);
        };

        calculateTotalPrice();
    }, [cartItems]);

    const isFormValid = () => {
        return (
          name.trim() !== '' &&
          lastname.trim() !== '' &&
          documentType.trim() !== '' &&
          documentNumber.trim() !== '' &&
          boardingPoint.trim() !== ''
        );
    };

    return (
        <div className='CheckoutContain'>
            <ToastContainer />
            <div className='subtitleContainer'>
                <p>Estas a punto de vivir una aventura increíble</p>
            </div>
            <form className='checkoutForm' method='POST'>
                <div className='cartContainer overflow-auto'>
                    {cartItems?.length === 0 ?
                    (<p className='nohay'> No hay productos</p>)
                    : (<>
                        <h4>Lista de tours seleccionados</h4>
                        <table>
                            <thead>
                                <th>Destino</th>
                                <th className='text-center'>Cantidad</th>
                                <th className='text-center'>Fecha</th>
                                <th className='text-center'>Precio unitario</th>
                                <th className='text-center'>Precio total</th>
                            </thead>
                            <tbody>
                        {cartItems.map((item, index) => (
                            <><tr>
                                <td>{item.titulo}</td>
                                <td className='text-center'>{item.cantidad}</td>
                                <td className='text-center'>{item.selectedDates}</td>
                                <td className='text-center'>S/{item?.precio?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                                <td className='text-center'>S/{(item?.precio * item?.cantidad)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                            </tr></>
                        ))}
                        <tr>
                            <td colSpan={4}><strong>Total:</strong></td>
                            <td className='text-center'><strong>S/{totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</strong></td>
                        </tr>
                        </tbody>
                        </table>
                    </>)}
                </div>
                <div className='checkoutFormContainer'>
                    <h4>Completa tus datos para finalizar la reserva</h4>
                    <div className='formRow'>
                        <div>
                            <label>Nombres</label>
                            <input
                            type='text'
                            name='client_name'
                            className='checkoutInput'
                            value={name || ''} 
                            onChange={(e) => setName(e.target.value)}  />
                        </div>
                        <div>
                            <label>Apellidos</label>
                            <input
                            type='text'
                            name='client_lastname'
                            className='checkoutInput'
                            value={lastname || ''} 
                            onChange={(e) => setLastName(e.target.value)}  />
                        </div>
                    </div>
                    <div className='formRow'>
                        <div>
                            <label>Tipo de documento</label>
                            <select 
                            className='checkoutSelect' 
                            name='document_type'
                            value={documentType || ''} 
                            onChange={(e) => setDocumentType(e.target.value)}>
                                <option>Seleccionar</option>
                                <option value='dni'>DNI</option>
                                <option value='pasaporte'>Pasaporte</option>
                                <option value='carnet de extranjería'>Carnet de extranjería</option>
                            </select>
                        </div>
                        <div>
                            <label>Número de documento</label>
                            <input
                            type='text'
                            name='document_number'
                            className='checkoutInput'
                            value={documentNumber || ''} 
                            onChange={(e) => setDocumentNumber(e.target.value)}  />
                        </div>
                    </div>
                    <div className='formRow'>
                        <div>
                            <label>Teléfono</label>
                            <input
                            type='number'
                            name='phone'
                            className='checkoutInput'
                            value={phone || ''} 
                            onChange={(e) => setPhone(e.target.value)}  />
                        </div>
                        <div>
                            <label>Email</label>
                            <input
                            type='text'
                            name='email'
                            className='checkoutInput'
                            value={email || ''} 
                            onChange={(e) => setEmail(e.target.value)}  />
                        </div>
                    </div>
                    <div className='formRow'>
                        <div>
                            <label>País</label>
                            <Select
                                className='customCountrySelect'
                                options={countries}
                                value={selectedCountry}
                                onChange={(selectedOption) => setSelectedCountry(selectedOption)}
                            />
                        </div>
                        <div>
                            <label>Punto de embarque</label>
                            <select 
                            className='checkoutSelect' 
                            name='boarding_point'
                            value={boardingPoint || ''} 
                            onChange={(e) => setBoardingPoint(e.target.value)}>
                                <option>Seleccionar</option>
                                <option value='san-borja'>Plaza norte</option>
                                <option value='plaza-norte'>San Borja</option>
                            </select>
                        </div>
                    </div>
                    <div className='formRow'>
                    {!formToken && (
                        <button 
                            className={`goToPayBtn ${buttonDisabled || !isFormValid() ? 'disabled' : ''}`} 
                            onClick={createPayment} 
                            disabled={!isFormValid() || buttonDisabled}
                        >{buttonDisabled ? 'Procesando...' : 'Ir al pago'}
                        </button>
                    )}
                    {formToken && (
                        <div className="kr-embedded" kr-popin="true" kr-form-token={formToken}></div>
                    )}
                    </div>
                </div>
            </form>
        </div>
    );
}
