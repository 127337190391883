import React from 'react';
import { Link as Anchor, } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, } from '@fortawesome/free-solid-svg-icons';
import './PageCheckout.css'
import HeaderBack from '../../Components/HeaderBack/HeaderBack';
import Checkout from '../../Components/Checkout/Checkout';
export default function PageCheckout() {

    return (
        <div>
            <div style={{
                backgroundImage: '',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '40vh',
            }} className='bgPage'>
                <HeaderBack title='Checkout' />
                <Anchor to={`/`}>
                    <FontAwesomeIcon icon={faHome} /> Inicio
                </Anchor>
                |
                <Anchor to=''>
                    Checkout
                </Anchor>
            </div>
            <Checkout />
        </div>
    );
}
