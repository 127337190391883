import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ConfiguracionPagosData.css';
import baseURL from '../../url';

export default function ConfiguracionPagosData() {
    const [mensaje, setMensaje] = useState('');
    const [config, setConfig] = useState([]);

    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = () => {
        fetch(`${baseURL}/paymentConfig.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setConfig(data.config[0] || []);
            })
            .catch(error => console.error('Error al cargar configuracion:', error));
    };
    
    const saveConfig = async () => {
        const form = document.getElementById("saveForm");
        const formData = new FormData(form);

        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/paymentConfig.php`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (data.mensaje) {
                setMensaje('');
                toast.success(data.mensaje);
                window.location.reload();
            } else if (data.error) {
                setMensaje('');
                toast.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('');
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div>
            <ToastContainer />
            <div className='form-container sectiontext'>
                <h2>Configuración de pago</h2>
                <form id='saveForm' className='flexGrap'>
                    <fieldset>
                        <legend>Usuario</legend>
                        <input
                            type="text"
                            value={config.username}
                            name="username"
                            onChange={(e) => setConfig({
                                username : e.target.value,
                            })}
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Contraseña</legend>
                        <input
                            type="text"
                            value={config.password}
                            name="password"
                            onChange={(e) => setConfig({
                                password : e.target.value,
                            })}
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Llave pública</legend>
                        <input
                            type="text"
                            value={config.public_key}
                            name="public_key"
                            onChange={(e) => setConfig({
                                public_key : e.target.value,
                            })}
                        />
                    </fieldset>
                    <fieldset>
                        <legend>SHA-KEY</legend>
                        <input
                            type="text"
                            value={config.sha_key}
                            name="sha_key"
                            onChange={(e) => setConfig({
                                sha_key : e.target.value,
                            })}
                        />
                    </fieldset>
                    {mensaje ? (
                        <button type="button" className='btnLoading' disabled>
                            {mensaje}
                        </button>
                    ) : (
                        <button type="button" onClick={saveConfig} className='btnPost'>
                            Actualizar
                        </button>
                    )}
                </form>
            </div>
        </div>
    );
};
