import './App.css';
import { router, } from "./Pages/index";
import { RouterProvider } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import baseURL from './Components/url';

function App() {
  const [config, setConfig] = useState([]);
    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = () => {
        fetch(`${baseURL}/processConfig.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setConfig(data.config[0] || []);
                const color = data.config[0];
                document.documentElement.style.setProperty('--color1', color.primary_color);
            })
            .catch(error => console.error('Error al cargar configuracion:', error));
    };

  return (

    <RouterProvider router={router} />

  );
}

export default App;
