import React, { useState, useEffect } from 'react';
import Nabvar from '../Components/Navbar/Navbar'
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';
import Footer from '../Components/Footer/Footer'
import BtnWhatsapp from '../Components/BtnWhatsapp/BtnWhatsapp';
import baseURL from '../Components/url';
export default function IndexLayout() {
    const isScreenLarge = useMediaQuery('(min-width: 900px)');
    const [config, setConfig] = useState([]);

    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = () => {
        fetch(`${baseURL}/processConfig.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setConfig(data.config[0] || []);
            })
            .catch(error => console.error('Error al cargar configuracion:', error));
    };
    return (
        <div >
            {isScreenLarge ?
                <>
                    <Nabvar config={config} />
                    <Outlet />
                    <Footer config={config} />
                    <BtnWhatsapp />
                </> :
                <>

                    <Outlet />
                    <Footer config={config} />

                </>}

            <div className='espaciobg2'>

            </div>

        </div>
    );
}
