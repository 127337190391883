import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';
import './OrdersData.css'

export default function OrdersData() {
    const [orders, setOrders] = useState([]);
    const [orderDetail, setOrderDetail] = useState([]);
    const [billingDetails, setBillingDetails] = useState([]);
    const [shoppingCart, setShoppingCart] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        getOrders();
    }, []);

    const getOrders = () => {
        fetch(`${baseURL}/adminOrders.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setOrders(data.orders || []);
            })
            .catch(error => console.error('Error al cargar reservas:', error));
    };

    const deleteOrder = (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/adminOrders.php?id=${id}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        getOrders();
                    })
                    .catch(error => {
                        console.error('Error al eliminar orden:', error);
                        toast.error(error);
                    });
            }
        });
    };

    const abrirModal = (item) => {
        setOrderDetail(item);
        const billingDetailsInfo = JSON.parse(item.billing_details);
        const shoppingCartInfo = JSON.parse(item.shopping_cart);
        console.log(shoppingCartInfo);
        setBillingDetails(billingDetailsInfo)
        setShoppingCart(shoppingCartInfo)
        setModalVisible(true);
    };

    const cerrarModal = () => {
        setModalVisible(false);
    };

    return (
        <div>
            <ToastContainer />

            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>
                            <div className='deFlexBtnsModal'>

                            </div>
                            <span className="close" onClick={cerrarModal}>
                                &times;
                            </span>
                        </div>
                        <h3 className='pl-1'>#{orderDetail.id}</h3>
                        <div className='dFlex'>
                            <div className='mt-4'>
                                <h4>Datos del Pedido</h4>
                                <p>Identificador en Izipay: {orderDetail.orderId}</p>
                                <p>Fecha de creación: {orderDetail.created_at}</p>
                                <p>Estado: {orderDetail.order_status}</p>
                                <p>Total: S/ {(orderDetail.total / 100).toFixed(2)}</p>
                                <p>Moneda: {orderDetail.currency}</p>
                                <p>Punto de embarque: {billingDetails.address}</p>
                            </div>
                            <div className='mt-4'>
                                <h4>Datos del Cliente</h4>
                                <p>Nombres: {billingDetails.firstName}</p>
                                <p>Apellidos: {billingDetails.lastName}</p>
                                <p>Email: {orderDetail.email}</p>
                                <p>Teléfono: {billingDetails.phoneNumber}</p>
                                <p>Pais: {billingDetails.country}</p>
                                <p>Tipo de documento: {billingDetails.identityType}</p>
                                <p>Número de documento: {billingDetails.identityCode}</p>
                            </div>
                        </div>
                        <div className='dFlex'>
                            <div className='mt-4'>
                                <h4>Datos del tour</h4>
                                {shoppingCart.map((item, index) => (
                                    <div key={index}>
                                        <h5 className='mt-2'>Tour {index + 1}</h5>
                                        <p>Nombre del Tour: {item.productLabel}</p>
                                        <p>Fecha de salida: {item.productRef}</p>
                                        <p>Cantidad de personas: {item.productQty}</p>
                                        <p>Precio del Tour: S/ {(item.productAmount)}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Estado</th>
                            <th>Total</th>
                            <th>Moneda</th>
                            <th>Email</th>
                            <th>Fecha de creación</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map(item => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.order_status}</td>
                                <td>S/ {(item.total / 100).toFixed(2)}</td>
                                <td>{item.currency}</td>
                                <td>{item.email}</td>
                                <td>{item.created_at}</td>
                                <td>
                                    <button className='eliminar' onClick={() => deleteOrder(item.id)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button className='editar' onClick={() => abrirModal(item)}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
