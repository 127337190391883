import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ConfiguracionData.css';
import baseURL from '../../url';
import NewCodigo from '../NewCodigo/NewCodigo';

export default function ConfiguracionData() {
    const [mensaje, setMensaje] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [config, setConfig] = useState([]);
    const [logoPreview, setLogoPreview] = useState(null);
    const [isLogoSelected, setisLogoSelected] = useState(false);

    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = () => {
        fetch(`${baseURL}/processConfig.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setConfig(data.config[0] || []);
            })
            .catch(error => console.error('Error al cargar configuracion:', error));
    };
    
    const saveConfig = async () => {
        const form = document.getElementById("saveForm");
        const formData = new FormData(form);

        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/processConfig.php`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (data.mensaje) {
                setMensaje('');
                toast.success(data.mensaje);
                window.location.reload();
            } else if (data.error) {
                setMensaje('');
                toast.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('');
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };

    const eliminarCodigo = (idCodigo) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/codigosDelete.php?idCodigo=${idCodigo}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        getConfig();
                    })
                    .catch(error => {
                        console.error('Error al eliminar código:', error);
                        toast.error(error);
                    });
            }
        });
    };

    const handleImagenChange = (event, setImagenPreview, setisLogoSelected) => {
        console.log("entro a la imagen");
        const file = event.target.files[0];

        if (file) {
            const previewURL = URL.createObjectURL(file);
            setImagenPreview(previewURL);
            setisLogoSelected(true);
        }
    };

    return (
        <div>
            <ToastContainer />
            <div className='form-container sectiontext'>
                <h2>Configuración de la web</h2>
                <div className='preview-img'>
                    {logoPreview && isLogoSelected ? (
                        <img src={logoPreview} alt="Vista previa de la imagen" />
                    ) : (
                        <>
                            {config.logo ? (
                                <img src={config.logo} alt="imagen" />

                            ) : (
                                <span className='imgNone'>
                                    No hay imagen
                                </span>
                            )}
                        </>
                    )}
                </div>
                <form id='saveForm' className='flexGrap'>
                    <fieldset>
                        <legend>Logo</legend>
                        <input
                            type="file"
                            accept="image/*"
                            name="logo"
                            onChange={(e) => handleImagenChange(e, setLogoPreview, setisLogoSelected)}
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Nombre de la web</legend>
                        <input
                            type="text"
                            value={config.web_name}
                            name="web_name"
                            onChange={(e) => setConfig({
                                web_name : e.target.value,
                                logo: config.logo,
                            })}
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Color principal</legend>
                        <input
                            type="text"
                            value={config.primary_color}
                            name="primary_color"
                            onChange={(e) => setConfig({
                                primary_color : e.target.value,
                                logo: config.logo,
                            })}
                        />
                    </fieldset>
                    {mensaje ? (
                        <button type="button" className='btnLoading' disabled>
                            {mensaje}
                        </button>
                    ) : (
                        <button type="button" onClick={saveConfig} className='btnPost'>
                            Actualizar
                        </button>
                    )}
                </form>
            </div>
        </div>
    );
};
