import React, { useState, useEffect } from 'react'
import './NavbarDashboard.css'
import { Link as Anchor, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faBook, faImage, faAddressBook, faTachometerAlt, faCode, faGear, faMoneyCheckAlt, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import logo from '../../../images/logo.png'
import Logout from '../Logout/Logout';
import baseURL from '../../url';

export default function Navbar() {
    const location = useLocation();
    const [config, setConfig] = useState([]);

    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = () => {
        fetch(`${baseURL}/processConfig.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setConfig(data.config[0] || []);
            })
            .catch(error => console.error('Error al cargar configuracion:', error));
    };


    return (

        <div class="navbarDashboard" >
            <Anchor className='logo'>
                <img src={config.logo} alt="logo" />
            </Anchor>
            <div className='links'>
                <Anchor to={`/dashboard`} className={location.pathname === '/dashboard' ? 'activeLink' : ''}><FontAwesomeIcon icon={faHome} /> Inicio</Anchor>
                <Anchor to={`/dashboard/reservas`} className={location.pathname === '/dashboard/reservas`' ? 'activeLink' : ''} ><FontAwesomeIcon icon={faCalendarCheck} /> Reservas</Anchor>
                <Anchor to={`/dashboard/tours`} className={location.pathname === '/dashboard/tours`' ? 'activeLink' : ''} ><FontAwesomeIcon icon={faBook} /> Tours</Anchor>
                <Anchor to={`/dashboard/categorias`} className={location.pathname === '/dashboard/categorias' ? 'activeLink' : ''}><FontAwesomeIcon icon={faTachometerAlt} /> Categorias</Anchor>
                <Anchor to={`/dashboard/subcategorias`} className={location.pathname === '/dashboard/subcategorias' ? 'activeLink' : ''}><FontAwesomeIcon icon={faCode} /> Subcategorias</Anchor>
                <Anchor to={`/dashboard/banners`} className={location.pathname === '/dashboard/banners' ? 'activeLink' : ''}><FontAwesomeIcon icon={faImage} /> Banners</Anchor>
                <Anchor to={`/dashboard/contacto`} className={location.pathname === '/dashboard/contacto' ? 'activeLink' : ''}><FontAwesomeIcon icon={faAddressBook} /> Contacto</Anchor>
                <Anchor to={`/dashboard/usuarios`} className={location.pathname === '/dashboard/usuarios' ? 'activeLink' : ''}><FontAwesomeIcon icon={faUser} /> Usuarios</Anchor>
                <Anchor to={`/dashboard/codigos`} className={location.pathname === '/dashboard/codigos' ? 'activeLink' : ''}><FontAwesomeIcon icon={faCode} /> Codigos</Anchor>
                <Anchor to={`/dashboard/configuracion`} className={location.pathname === '/dashboard/configuracion' ? 'activeLink' : ''}><FontAwesomeIcon icon={faGear} /> Configuración de la web</Anchor>
                <Anchor to={`/dashboard/configuracion/pagos`} className={location.pathname === '/dashboard/configuracion/pagos' ? 'activeLink' : ''}><FontAwesomeIcon icon={faMoneyCheckAlt} /> Configuración de pagos</Anchor>
            </div>

            <Logout />

        </div>

    );
}
