import React from 'react'
import Header from '../Header/Header'
import ConfiguracionData from '../../Components/Admin/ConfiguracionData/ConfiguracionData'
import HeaderDash from '../../Components/Admin/HeaderDash/HeaderDash'
export default function Configuracion() {
    return (
        <div className='containerGrid'>
            <Header />

            <section className='containerSection'>

                <HeaderDash />
                <div className='container'>
                    <ConfiguracionData />
                </div>
            </section>
        </div>
    )
}

