import React, { useEffect, useState } from 'react';
import Banners from '../../Components/Banners/Banners'
import Products from '../../Components/Products/Products'
import './Demo.css'
import Footer from '../../Components/Footer/Footer'
import BtnWhatsapp from '../../Components/BtnWhatsapp/BtnWhatsapp'
import Cart from '../../Components/Cart/Cart'
import baseURL from '../../Components/url';
import { Helmet } from 'react-helmet';
export default function Demo() {
    const [config, setConfig] = useState([]);
    useEffect(() => {
        getConfig();
    }, []);

    const getConfig = () => {
        fetch(`${baseURL}/processConfig.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setConfig(data.config[0] || []);
            })
            .catch(error => console.error('Error al cargar configuracion:', error));
    };
    return (
        <section className='demo'>
            <Helmet>
                <title>{config.web_name}</title>
                <meta name="description" content={config.web_name} />
            </Helmet>
            <Banners />
            <Products />
            <Footer config={config} />
            <BtnWhatsapp />
            <Cart />
        </section>
    )
}
